import { useNavigation } from "@remix-run/react";
import { ReactNode } from "react";
import { useBaseHomeUrl } from "~/hooks";
import { MonetaStudioLogo } from "./images";
import { IndeterminateProgress } from "./IndeterminateProgress";
import { MonetaStudioBrand } from "./MonetaStudioBrand";

export const DefaultLayout = ({ children, homeUrl: homeUrlOverride }: { children: ReactNode; homeUrl?: string }) => {
  const navigation = useNavigation();
  const baseHomeUrl = useBaseHomeUrl();
  const homeUrl = homeUrlOverride ?? baseHomeUrl;

  return (
    <div className="flex flex-col">
      {(navigation.state === "loading" || navigation.state === "submitting") && (
        <div className="fixed top-0 w-full">
          <IndeterminateProgress />
        </div>
      )}
      <div className="mb-6 flex flex-col px-2 py-4">
        {homeUrl ? <MonetaStudioBrand to={homeUrl} /> : <MonetaStudioLogo />}
      </div>
      {children}
    </div>
  );
};
